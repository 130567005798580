import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function Twitter({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9535 8.04591L19.9652 8.57716C19.9652 13.9979 15.8371 20.2506 8.2918 20.2506C6.06128 20.2518 3.87738 19.6121 2 18.4076C4.15206 18.6694 6.37043 18.0505 8.07695 16.71C6.34161 16.6751 4.77719 15.5107 4.2457 13.8584M21.4492 4.29708C20.7378 5.0016 19.8764 5.38556 18.8445 5.29279C18.2257 4.63524 17.4075 4.19987 16.5164 4.05399C15.6253 3.9081 14.711 4.05982 13.9148 4.48569C13.1186 4.91156 12.4848 5.58787 12.1115 6.41004C11.7382 7.23221 11.6462 8.15446 11.8496 9.03419C8.57625 8.86993 5.46269 7.29243 3.39414 4.75021M2.80469 9.76466C3.36333 10.3637 4.33519 11.3323 6.5 12"
        stroke={color}
        strokeMiterlimit={2.509_86}
        strokeWidth={2}
      />
    </svg>
  )
}

export default React.memo(Twitter)
